<template>
  <div class="personalGuarantee">
    <!-- 个人担保 -->
    <div class="head">
      <TitleCenter :rules="false" name="个人担保" />
      <div class="addbutton" @click="addPersonal">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="personalGuarantee"
      :tableAttrs="{
        data: personalData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <!-- 插入子表格 -->
      <template slot="code" slot-scope="scope">
        <base-table
          :columns="personalFormChildren"
          :tableAttrs="{
            data: scope.row.personContactInfos,
            stripe: true,
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <template slot="action" slot-scope="scope">
            <icon-Button
              @click="removePersonaChildren(scope.row, scope.$index)"
              content="删除"
              icon="iconfont iconshanchu1"
            ></icon-Button>
          </template>
        </base-table>
      </template>
        <template slot="index" slot-scope="scope">
           {{ scope.$index + 1}}
           </template>
      <template slot="action" slot-scope="scope">
        <icon-Button
          @click="removePersona(scope.row,scope.$index)"
          content="删除"
          icon="iconfont iconshanchu1"
        ></icon-Button>
      </template>
    </base-table>
    <!-- 添加个人担保弹窗 -->
    <add-personal
    :visible="personalState"
     @emitVisible="personalState = false"
     :personalData='personalData'
    ></add-personal>
  </div>
</template>
<script>
import TitleCenter from '@/pages/business/components/title-center.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { personalTabel, personalFormChildren } from '../utils/financing-info-config'
import AddPersonal from '../components/add-personal.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'

export default {
  components: { BaseTable, IconButton, AddPersonal, TitleCenter },
  name: 'personalGuarantee',
  props: {
    // personalData: Array
  },
  data () {
    return {
      personalState: false, // 增加弹窗状态
      personalData: []
    }
  },
  computed: {
    // 子列表
    personalFormChildren () {
      return personalFormChildren(this)
    },
    // 表格
    personalGuarantee () {
      return personalTabel(this)
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
  },
  mounted () {
    if (this.businessId) {
      this.getPersonalData()
    }
  },
  watch: {},

  methods: {
    // 获取个人担保数据
    getPersonalData () {
      financeAdmittanceApi.getPersonnalList({ businessId: this.businessId }).then(res => {
        console.log(res.data, '个人数据')
        this.personalData = res.data ? res.data : []
      })
    },
    // 添加个人担保
    addPersonal () {
      this.personalState = true
    },
    //  删除个人担保数据
    removePersona (data, index) {
      financeAdmittanceApi.delByPersonnal([data.keyId]).then(res => {
        if (res.data) {
          // this.personalData[index].splice(index, 1)
          this.getPersonalData()
          this.wraning('删除成功')
        }
      })
    },
    // 删除子列表
    removePersonaChildren (data, index) {
      // console.log(data)
      financeAdmittanceApi.delPersonalContact([data.keyId]).then(res => {
        if (res.data) {
          this.getPersonalData()
          this.wraning('删除成功')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.personalGuarantee {
  padding: 10px;
}
.head {
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 8px;
  // 页面按钮
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
